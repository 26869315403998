import React from "react";

export const Team = (props) => {
  return (
    <div id="team" >
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2 className="text-center">Meet the Team</h2>
        </div>
        <div id="row">
          {props.data
            ?
            <>
              <div className="container">

                {/* member 1 */}
                <div className="row" id="teamDetails">
                  <div className="col-xs-12 col-md-8" style={{ marginBottom: '20px' }}>
                    <div className="about-text">
                      <h2>Puja Shah</h2>
                      <h4>FCA, CS, M.com</h4>
                      <p>{props.data ? props.data.member2 : "loading..."}</p>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-4">
                    {" "}
                    <img src="img/pooja.jpg" className="img-responsive" alt="" style={{ width: '250px', height: '250px', objectFit: 'fill' }}/>{" "}
                  </div>
                </div>

                {/* Member 2 */}
                <div className="row" style={{ marginBottom: '20px' }}>
                  <div className="col-xs-12 col-md-3">
                    {" "}
                    <img src="img/dipen.jpeg" className="img-responsive" alt="" style={{ width: '250px', height: '250px', objectFit: 'fill' }} />{" "}
                  </div>
                  <div className="col-xs-12 col-md-9">
                    <div className="about-text">
                      <h2>Dipen Shah</h2>
                      <h4>FCA, M.com</h4>
                      <p>{props.data ? props.data.member1 : "loading..."}</p>
                    </div>
                  </div>
                </div>

                {/* member 3 */}
                <div className="row" id="teamDetails" style={{ marginBottom: '20px' }}>
                  <div className="col-xs-12 col-md-8">
                    <div className="about-text">
                      <h2>Devansh Shah</h2>
                      <h4>CA, DISA, M.Com</h4>
                      <p>{props.data ? props.data.member5 : "loading..."}</p>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-4">
                    {" "}
                    <img src="img/devansh.jpeg" className="img-responsive" alt="" style={{ width: '250px', height: '250px', objectFit: 'fill' }}/>{" "}
                  </div>
                </div>


                {/* Member 4 */}
                <div className="row" style={{ marginBottom: '20px' }}>
                  <div className="col-xs-12 col-md-3">
                    {" "}
                    <img src="img/komal.jpeg" className="img-responsive" alt="" style={{ width: '250px', height: '250px', objectFit: 'fill' }} />{" "}
                  </div>
                  <div className="col-xs-12 col-md-9">
                    <div className="about-text">
                      <h2>Komal Thakkar</h2>
                      <h4>CA, B.Com</h4>
                      <p>{props.data ? props.data.member6 : "loading..."}</p>
                    </div>
                  </div>
                </div>

                {/* member5 */}
                <div className="row" id="teamDetails" style={{ marginBottom: '20px' }}>
                  <div className="col-xs-12 col-md-8">
                    <div className="about-text">
                      <h2>Niraj Patel</h2>
                      <h4>B. Com, Inter CA</h4>
                      <p>{props.data ? props.data.member3 : "loading..."}</p>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-4">
                    {" "}
                    <img src="img/niraj.jpg" className="img-responsive" alt="" style={{ width: '250px', height: '250px', objectFit: 'fill' }}/>{" "}
                  </div>
                </div>

                {/* Member 6 */}
                <div className="row" style={{ marginBottom: '20px' }}>
                  <div className="col-xs-12 col-md-3">
                    {" "}
                    <img src="img/parth.jpeg" className="img-responsive" alt="" style={{ width: '250px', height: '250px', objectFit: 'fill' }} />{" "}
                  </div>
                  <div className="col-xs-12 col-md-9">
                    <div className="about-text">
                      <h2>Parth Patel</h2>
                      <h4>Inter CA, B.Com</h4>
                      <p>{props.data ? props.data.member4 : "loading..."}</p>
                    </div>
                  </div>
                </div>

              </div>
            </>
            : "loading"}
        </div>
      </div>
    </div>
  );
};
