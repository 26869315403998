import React from "react";

export const Image = ({ title, largeImage, smallImage }) => {
  const handleClick = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    window.open(largeImage, '_blank'); // Open the large image URL in a new tab
  };
  const imageStyle = {
    width: '390px',   // Set your desired width
    height: '200px',  // Set your desired height
    objectFit: 'cover',  // Ensures the image covers the container without distortion
    cursor: 'pointer',
  };
  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        <div onClick={handleClick} style={{ cursor: 'pointer' }}>
          <div className="hover-text">
            <h4>{title}</h4>
          </div>
          <img 
            src={smallImage} 
            className="img-responsive" 
            alt={title} 
            style={imageStyle} 
          />
        </div>
      </div>
    </div>
  );
};
